import { Outlet, useNavigate, useSearchParams } from "react-router";
import React, { useEffect, useState } from "react";
import { useUser } from "~/hooks/user";
import metrics from "~/utils/metrics";

export default function LoginWrapper() {
  const { authenticated, ...user } = useUser();
  const [params] = useSearchParams();
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authenticated) {
      setReady(true);
      return;
    }
    let redirectUrl = "/";
    if (params.get("redirectUrl")) {
      redirectUrl = decodeURIComponent(params.get("redirectUrl") || "/");
    }
    if (!redirectUrl.startsWith("http")) {
      navigate(redirectUrl);
    } else {
      window.location.href = redirectUrl;
    }
  }, [authenticated]);

  useEffect(() => {
    if (params.has("loggedOut")) {
      metrics.reset();
    }
  }, [params]);

  if (!ready) return null;
  return <Outlet />;
}
